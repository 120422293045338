import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { useAccessToken } from '@/hooks';
import { ApiConnector } from '@/services/ApiConnector';
import { AppDispatch, RootState } from '@/store';
import { editSimulationTitle, resetWIP } from '@/store/storeSlice';
import {
  Scenario,
  ScenarioSettingsParameters,
  WipTree,
  ZipScenario,
} from '@/store/types';
import {
  Button,
  ButtonIcon,
  Divider,
  EditableField,
  Input,
  Typography,
  useDateTimeFormat,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import JSZip from 'jszip';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScenarioListItem } from './ScenarioListItem';

/**
 * SimulationStarted Component
 * Renders the simulation details and provides options to launch or download the simulation
 * Manages the state for showing download options and mouse hover effects
 *
 * @returns {JSX.Element} - The rendered component
 */
const SimulationStarted = () => {
  const [showDownloadZip, setShowDownloadZip] = useState(false);
  const { getSilentToken } = useAccessToken();
  const dispatch: AppDispatch = useDispatch();
  const simulation = useSelector((state: RootState) => state.store.Simulation);
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  const [isMouseOver, setIsMouseOver] = useState(false);
  const { setSimulationParams } = useContext(SimulationCtx);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  /**
   * Handles the revert action
   * Confirms the deletion of the simulation and resets the simulation parameters
   *
   * @returns {boolean} - True if the simulation is deleted, false otherwise
   */
  const revert = () => {
    // eslint-disable-next-line no-restricted-globals
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        'Are you sure you want to delete the simulation?\n\nThis action cannot be undone.',
      )
    ) {
      // dispatch(deleteSimulation());
      dispatch(resetWIP());

      setSimulationParams((prevState) => ({
        ...prevState,
        simulationForm: false,
        simulationStarted: false,
      }));

      /*  dispatch({
          type: "RESET_TO_WIP",
        }); */
      return true;
    } else {
      return false;
    }
  };

  /**
   * Downloads the gzipped JSON data as a zip file
   *
   * @param {BlobPart} compressedData - The compressed JSON data
   * @param {string} filename - The name of the file to be downloaded
   */
  const downloadGzippedJSON = (compressedData: BlobPart, filename: string) => {
    // Gzip the JSON data
    //const compressedData = gzipJSON(json);

    // Create a Blob from the compressed data
    const blob = new Blob([compressedData], { type: 'application/zip' });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up the temporary anchor element
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  type WipTreeKey = keyof WipTree;
  type TPresignedFields = {
    key: string;
    policy: string;

    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-security-token': string;
    'x-amz-signature': string;
  };

  /**
   * Extracts the simulation ID from a given string
   *
   * @param {string} inputString - The input string containing the simulation ID
   * @returns {string} - The extracted simulation ID
   */
  function extractSimulationId(inputString: string) {
    const regex = /simulationId=([a-f\d-]+)/;
    const match = regex.exec(inputString);
    if (match) return match[1];
    return '';
  }

  async function launchSimulation(onlyZip: boolean) {
    if (!onlyZip) {
      if (confirm('Are you sure you want launch the simulation?')) {
        //
      } else {
        return false;
      }
    }

    if (!onlyZip) {
      setSimulationParams(
        produce((draft) => {
          draft.loaderMessage = {
            messages: [
              'Preparing files',
              'Compressing files',
              'Uploading files',
              'Inserting in Database',
              'Launching Simulation',
            ],
            time: 6000 * 3,
          };
        }),
      );
    }

    const url = import.meta.env.VITE_API_URL;
    const accessToken = await getSilentToken();
    const response = await ApiConnector(
      'GET',
      'scenarios',
      { baseUrl: url, accessToken: accessToken },
      { 'Content-Type': 'application/json', Accept: 'application/json' },
    );

    const presignedUrl = response.data.url;
    const presignedFields: TPresignedFields = response.data.fields;

    const zip = new JSZip();
    simulation.scenarios.forEach((scenario: ZipScenario, index) => {
      // Create a new array for modified parameters
      const modifiedParameters = scenario.settings.parameters.map((param) => {
        const newParam: Partial<ScenarioSettingsParameters> = {};

        if (param.DropDown_WaitOnBatchTime === 1) {
          newParam.Fixed_WaitOnBatchTimeAsFixedHours =
            param.Fixed_WaitOnBatchTimeAsFixedHours;
        }

        if (
          param.DropDown_WaitOnBatchTime === 2 &&
          param.Variable_WaitOnBatchTimeAsProcessTimePercentage
        ) {
          newParam.Variable_WaitOnBatchTimeAsProcessTimePercentage =
            param.Variable_WaitOnBatchTimeAsProcessTimePercentage / 100;
        }

        if (param.Batch_Grouping_Option !== 1) {
          newParam.Batch_Fill_Grade_Percentage =
            param.Batch_Fill_Grade_Percentage;
        }

        // Include other parameters as they are
        newParam.DropDown_WaitOnBatchTime = param.DropDown_WaitOnBatchTime;
        newParam.DropDown_JobsPrioritisation =
          param.DropDown_JobsPrioritisation;
        newParam.DropDown_UseOfAdditionalEquipment =
          param.DropDown_UseOfAdditionalEquipment;
        newParam.DropDown_UseEachPOStartDate =
          param.DropDown_UseEachPOStartDate;
        newParam.DropDown_FileSelection = param.DropDown_FileSelection;
        newParam.DropDown_Use_Storage = param.DropDown_Use_Storage;
        newParam.DropDown_Use_Personnel_Module =
          param.DropDown_Use_Personnel_Module;
        newParam.Batch_Grouping_Option = param.Batch_Grouping_Option;
        newParam.DropDown_Worker_Pooling = param.DropDown_Worker_Pooling;
        newParam.DropDown_Scrap_Trigger = param.DropDown_Scrap_Trigger;
        newParam.DropDown_Scrap_Delete = param.DropDown_Scrap_Delete;

        return newParam;
      });

      console.log('Running with params: ', modifiedParameters);
      // Create a new settings object with modified parameters
      const newSettings = {
        ...scenario.settings,
        parameters: modifiedParameters,
      };

      zip.file(
        `scenario_${index + 1}/scenario_settings.json`,
        JSON.stringify(newSettings),
        {
          compression: 'DEFLATE',
          compressionOptions: { level: 9 },
        },
      );

      for (const keyWip in scenario.data) {
        const newData = [];
        try {
          const tree = scenario.data[keyWip as WipTreeKey].byId;

          for (const key in tree) {
            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              children, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_disabled, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_deleted, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_match, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_open, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_selectable, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_selected, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              is_hidden, // eslint-disable-next-line @typescript-eslint/no-unused-vars
              parent,
              ...otherProps
            } = tree[key];

            const element = JSON.parse(JSON.stringify(otherProps));

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const elementWithKey: { [key: string]: any } = {};

            for (const key in element) {
              try {
                if (element[key] !== undefined && element[key].value !== null)
                  elementWithKey[key] = element[key].value.toString();
              } catch (e) {
                console.log('ERROR', key, e);
              }
            }
            newData.push(elementWithKey);
          }

          zip.file(
            `scenario_${index + 1}/${keyWip}.json`,
            JSON.stringify(newData),
            {
              compression: 'DEFLATE',
              compressionOptions: { level: 9 },
            },
          );
        } catch (e) {
          console.log('ERROR', keyWip, e);
        }
      }
    });
    const zipFileBlob = await zip.generateAsync({ type: 'blob' });

    if (onlyZip) {
      downloadGzippedJSON(zipFileBlob, simulation.title + '.zip');
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('x-amz-algorithm', presignedFields['x-amz-algorithm']);
    searchParams.append(
      'x-amz-credential',
      presignedFields['x-amz-credential'],
    );
    searchParams.append('x-amz-date', presignedFields['x-amz-date']);
    searchParams.append(
      'x-amz-security-token',
      presignedFields['x-amz-security-token'],
    );
    searchParams.append('x-amz-signature', presignedFields['x-amz-signature']);
    searchParams.append('policy', presignedFields['policy']);
    searchParams.toString();

    const formData = new FormData();

    formData.append('key', presignedFields['key']);
    formData.append('x-amz-algorithm', presignedFields['x-amz-algorithm']);
    formData.append('x-amz-credential', presignedFields['x-amz-credential']);
    formData.append('x-amz-date', presignedFields['x-amz-date']);
    formData.append(
      'x-amz-security-token',
      presignedFields['x-amz-security-token'],
    );
    formData.append('x-amz-signature', presignedFields['x-amz-signature']);
    formData.append('policy', presignedFields['policy']);
    formData.append('file', zipFileBlob, 'scenarios.zip');
    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    // Make the POST request
    fetch(presignedUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        // Handle the response
        console.log(response);

        console.log('ZIP SENT', response);
        setTimeout(async () => {
          const responseSimulation = await ApiConnector(
            'POST',
            'simulations',
            { baseUrl: url, accessToken: accessToken },
            { 'Content-Type': 'application/json', Accept: 'application/json' },
            {
              simulationId: extractSimulationId(presignedFields['key']),
              simulationTitle: simulation.title,
              simulationDescription: simulation.description,
            },
          );

          console.log('responseSimulation', responseSimulation);
        }, 3000 * 3);

        setTimeout(() => {
          dispatch(resetWIP());

          setSimulationParams((prevState) => ({
            ...prevState,
            simulationForm: false,
            simulationStarted: false,
            factoryDataLoaded: null,
            loaderMessage: '',
          }));
        }, 4500 * 3);
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });

    return;

    const responsePost = await ApiConnector(
      'POST',
      presignedUrl,
      { baseUrl: '', accessToken: '' },
      {
        'Content-Type': 'binary/octet-stream',
        Accept: '*',
        /*  'Content-Type': 'application/json',
        Accept: 'application/json', */
        /* key: presignedFields.key,
        policy: presignedFields.policy,
        'x-amz-algorithm': presignedFields['x-amz-algorithm'],
        'x-amz-credential': presignedFields['x-amz-credential'],
        'x-amz-date': presignedFields['x-amz-date'],
        'x-amz-security-token': presignedFields['x-amz-security-token'],
        'x-amz-signature': presignedFields['x-amz-signature'], */
      },
      {
        ...presignedFields,
        file: zipFileBlob,
      },
    );
    console.log(responsePost);
    // downloadGzippedJSON(zipFileBlob, 'teste123.zip');
    //return ZIP
    // console.log(simulation.scenarios[0].data.resource_classes.allIds);
  }

  const updateTitle = (str: string) => {
    //if title is empty do not update
    if (str.trim().length < 1) return;

    //update title - run the reducer
    dispatch(
      editSimulationTitle({
        title: str.trim(),
      }),
    );
  };

  return (
    <>
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{
          padding: 20,
          fontWeight: 'bold',
          borderBottom: 'solid 1px #ededed',
        }}
      >
        {isMouseOver && (
          <div
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              opacity: 0.8,
            }}
          >
            <ButtonIcon
              icon="IconTrash"
              tooltipMessage="Delete simulation"
              onClick={revert}
              size="small"
            />
          </div>
        )}
        <Typography tag="textdefault_strong">
          <EditableField
            onChange={() => {}}
            onEnter={(v) => updateTitle(v)}
            value={simulation.title}
            mouseOverEffect="underline"
            tag="textdefault_strong"
            tooltipTitle="Edit simulation title"
            tooltipColor={'dark'}
          >
            <div style={{ marginTop: 20 }}>
              <Input
                size="small"
                autofocus
                defaultValue={simulation.title}
                id="input1"
                onFocus={() => setIsMouseOver(false)}
              />
            </div>
          </EditableField>
        </Typography>
        <div style={{ marginTop: 5 }}>
          <Typography tag="textsmall" color="#999999">
            {useDateTimeFormat(simulation.date_start * 1000)} to{' '}
            {useDateTimeFormat(simulation.date_end * 1000)}
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}></div>
        {simulation.description && (
          <div style={{ marginTop: 10 }}>
            <Typography tag="textextrasmall" color="#999999">
              {simulation.description}
            </Typography>
          </div>
        )}

        {selectedScenario && (
          <div style={{ marginTop: 10 }}>
            <Typography tag="textdefault" color="#999999">
              Sites
            </Typography>
            {selectedScenario.data.sites.allIds.map((siteId) => {
              const site =
                selectedScenario.data.sites.byId[siteId].SITE_ABBREVIATION
                  .value;
              return (
                <div key={siteId} style={{ marginTop: 5 }}>
                  <Typography tag="textsmall">{site}</Typography>
                </div>
              );
            })}
          </div>
        )}

        <div
          style={{
            marginTop: 20,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <Button
            onClick={() => launchSimulation(false)}
            type="primaryOutline"
            width="100%"
          >
            Launch simulation
          </Button>

          {showDownloadZip || import.meta.env.VITE_SHOW_DOWNLOAD_ZIP === '1' ? (
            <Button
              type="link"
              size="small"
              onClick={() => launchSimulation(true)}
            >
              Download Zip
            </Button>
          ) : (
            ''
          )}
        </div>
        <button
          onClick={() => setShowDownloadZip(true)}
          style={{
            opacity: 0,
            position: 'absolute',
            bottom: 20,
            height: 20,
            width: '100%',
            cursor: 'pointer',
          }}
        ></button>
      </div>
      <Divider />
      {simulation.is_started ? (
        <>
          {simulation.scenarios.map((item: Scenario, index) => (
            <ScenarioListItem key={index} item={item} />
          ))}
        </>
      ) : null}
    </>
  );
};

export default SimulationStarted;
