import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import { update } from '@/store/storeSlice';
import { TreeItem } from '@/store/types';
import {
  Button,
  DatePicker,
  Input,
  Label,
  Select,
  SelectOption,
  Slider,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type TFormProductionOrders = {
  PRODUCTION_ORDER_ID: TreeItem;
  PRODUCTION_ORDER: TreeItem;
  PO_START_DATE_TIMESTAMP: TreeItem<number>;
  PO_END_DATE_TIMESTAMP: TreeItem<number>;
  SITE_ID: TreeItem;
  WORK_PLAN_ID: TreeItem;
  PRODUCT_ID: TreeItem;
  PO_LAST_STEP_WIP: TreeItem<number>;
};

/**
 * FormProductionOrders Component
 * Manages the form for editing production order details within a simulation scenario
 * Handles form submission, input changes, date changes, and closing the form drawer
 */
const FormProductionOrders = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );
  const InitialFormProductionOrders: TFormProductionOrders = {
    PRODUCTION_ORDER_ID: { value: '', originalValue: '' },
    PRODUCTION_ORDER: { value: '', originalValue: '' },
    PO_START_DATE_TIMESTAMP: { value: 0, originalValue: 0 },
    PO_END_DATE_TIMESTAMP: { value: 0, originalValue: 0 },
    SITE_ID: { value: '', originalValue: '' },
    WORK_PLAN_ID: { value: '', originalValue: '' },
    PRODUCT_ID: { value: '', originalValue: '' },
    PO_LAST_STEP_WIP: { value: 0, originalValue: 0 },
  };

  const [form, setForm] = useState(InitialFormProductionOrders);

  /**
   * handleDateChange Function
   * Updates the form state when a date value changes
   * Adjusts the date to 8 AM and adds a random number for uniqueness
   *
   * @param {keyof TFormProductionOrders} id - The form field ID
   * @param {number | null} value - The new date value
   * @param {'start' | 'end'} typeDate - The type of date (start or end)
   */
  const handleDateChange = (
    id: keyof TFormProductionOrders,
    value: number | null,
    typeDate: 'start' | 'end',
  ) => {
    const updateForm = (id: keyof TFormProductionOrders, newValue: unknown) => {
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: newValue ?? 0,
        },
      }));
    };

    let treatedValue: number;
    if (!value) {
      treatedValue = 0;
    } else {
      const date = new Date(value * 1000); // Multiply by 1000 to convert from seconds to milliseconds

      // Set the time to 8 AM
      date.setHours(8, 0, 0, 0);

      // Convert the modified date back to a timestamp
      treatedValue = Math.floor(date.getTime() / 1000);

      //generate a random number to create uniqueness
      const randomNumber = Math.floor(Math.random() * 3600 * 4) + 1;
      if (typeDate === 'end') {
        treatedValue = value + randomNumber * 2;
      } else {
        treatedValue = value + randomNumber;
      }
    }

    updateForm(id as keyof TFormProductionOrders, treatedValue);
  };

  /**
   * handlePercentageChange Function
   * Updates the form state when the percentage value changes
   * Combines the integer part of the step with the new percentage value
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>} event - The input change event
   */
  const handlePercentageChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      PO_LAST_STEP_WIP: {
        ...prevForm.PO_LAST_STEP_WIP,
        value:
          parseFloat(
            Math.floor(prevForm.PO_LAST_STEP_WIP.value).toString() +
              '.' +
              value.toString(),
          ) ?? 0,
      },
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { id, value } = event.target as HTMLInputElement & {
      id: string;
      value: string;
      type: string;
      checked: boolean;
    };
    const updateForm = (id: keyof TFormProductionOrders, newValue: unknown) => {
      let v = newValue;
      if (typeof form[id] === 'number') {
        v = parseInt(v as string);
      }
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: v,
        },
      }));
    };

    updateForm(id as keyof TFormProductionOrders, value);
  };

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setForm(InitialFormProductionOrders);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      update({
        id: simulationParams.formItem,
        area: 'production_orders',
        content: form,
      }),
    );

    handleCloseDrawer();
  };

  const selectedItem =
    simulationParams.formItem && selectedScenario
      ? selectedScenario.data.production_orders.byId[simulationParams.formItem]
      : null;

  const [editingWorkplan, setEditingWorkplan] = useState(false);
  const [workplanSteps, setWorkplanSteps] = useState<string[]>([]);

  const handleEditWorkplan = (workplanId: string) => {
    if (!selectedScenario) return;

    const workplan = selectedScenario.data.work_plans.byId[workplanId];
    setWorkplanSteps(workplan.children);
    setEditingWorkplan(true);
  };

  const handleSubmitWorkplan = () => {
    if (!selectedScenario) return;

    setForm((prevForm) => ({
      ...prevForm,
      WORK_PLAN_ID: {
        ...prevForm.WORK_PLAN_ID,
        value: form.WORK_PLAN_ID.value,
      },
    }));

    dispatch(update(
      {
        id: form.WORK_PLAN_ID.value,
        area: 'work_plans',
        content: {
          ...selectedScenario.data.work_plans.byId[form.WORK_PLAN_ID.value],
          children: workplanSteps,
        },
      },
    ));

    setEditingWorkplan(false);
  };

  const handleStepChange = (index: number, newStepValue: string) => {
    const updatedSteps = [...workplanSteps];
    const stepId = updatedSteps[index];
    const step = selectedScenario?.data.operations.byId[stepId];
    if (step) {
      dispatch(
        update({
          id: stepId,
          area: 'operations',
          content: {
            ...step,
            STEP: {
              value: Number(newStepValue),
              originalValue: step.STEP.originalValue,
            },
          },
        }),
      );
    }
    setWorkplanSteps(updatedSteps);
  };

  const generateStepOptions = (maxSteps: number) => {
    const options = [];
    for (let i = 1; i <= maxSteps; i++) {
      options.push(
        <SelectOption key={i} value={i.toString()} label={i.toString()} />,
      );
    }
    return options;
  };

  useEffect(() => {
    if (!selectedItem) return;

    const formToChange: TFormProductionOrders = {
      PRODUCTION_ORDER_ID: {
        value: selectedItem.PRODUCTION_ORDER_ID.value,
        originalValue: selectedItem.PRODUCTION_ORDER_ID.originalValue,
      },

      PRODUCTION_ORDER: {
        value: selectedItem.PRODUCTION_ORDER.value,
        originalValue: selectedItem.PRODUCTION_ORDER.originalValue,
      },
      PO_START_DATE_TIMESTAMP: {
        value: selectedItem.PO_START_DATE_TIMESTAMP.value,
        originalValue: selectedItem.PO_START_DATE_TIMESTAMP.originalValue,
      },

      PO_END_DATE_TIMESTAMP: {
        value: selectedItem.PO_END_DATE_TIMESTAMP.value,
        originalValue: selectedItem.PO_END_DATE_TIMESTAMP.originalValue,
      },
      SITE_ID: {
        value: selectedItem.SITE_ID.value,
        originalValue: selectedItem.SITE_ID.originalValue,
      },
      WORK_PLAN_ID: {
        value: selectedItem.WORK_PLAN_ID.value,
        originalValue: selectedItem.WORK_PLAN_ID.originalValue,
      },
      PRODUCT_ID: {
        value: selectedItem.PRODUCT_ID.value,
        originalValue: selectedItem.PRODUCT_ID.originalValue,
      },
      PO_LAST_STEP_WIP: {
        value: selectedItem.PO_LAST_STEP_WIP.value,
        originalValue: selectedItem.PO_LAST_STEP_WIP.originalValue,
      },
    };
    setForm(formToChange);
  }, [selectedItem]);

  if (!selectedItem || !selectedScenario) return;

  type TSelect = {
    id: string;
    name: string;
  };

  const sites: TSelect[] = [];
  selectedScenario.data.sites.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.sites.byId[itemId];

    const newItem = {
      id: item.SITE_ID.originalValue,
      name: item.SITE.value,
    };

    sites.push(newItem);
  });
  const workplans: TSelect[] = [];
  selectedScenario.data.work_plans.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.work_plans.byId[itemId];

    const newItem = {
      id: item.WORK_PLAN_ID.originalValue,
      name: item.WORK_PLAN.value,
    };

    workplans.push(newItem);
  });
  const steps: TSelect[] = [];

  const PO_steps =
    selectedScenario.data.work_plans.byId[selectedItem.WORK_PLAN_ID.value]
      .children;

  PO_steps.forEach((itemId: string) => {
    const item = selectedScenario.data.operations.byId[itemId];

    const newItem = {
      id: item.STEP.value,
      name: item.OPERATION.value,
    };

    steps.push(newItem);
  });

  const products: TSelect[] = [];
  selectedScenario.data.products.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.products.byId[itemId];

    const newItem = {
      id: item.PRODUCT_ID.originalValue,
      name: item.PRODUCT.value,
    };

    products.push(newItem);
  });

  const formMargin = 30;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 20,
            marginBottom: formMargin,
            marginTop: formMargin / 2,
          }}
        >
          <div>
            <Label tag="textsmall_strong">End Production Order</Label>
            <Typography tag="textsmall">
              {
                selectedScenario.data.production_orders.byId[
                  selectedItem.END_PRODUCTION_ORDER_ID.value
                ].PRODUCTION_ORDER.value
              }
            </Typography>
          </div>
          <div>
            <Label tag="textsmall_strong">End Product</Label>
            <Typography tag="textsmall">
              {selectedItem.END_PRODUCT_ID.value
                ? selectedScenario.data.products.byId[
                    selectedItem.END_PRODUCT_ID.value
                  ].PRODUCT.value
                : 'N/A'}
            </Typography>
          </div>
        </div>
        <Label tag="textsmall_strong">Production Order</Label>
        <Input
          size="small"
          id="PRODUCTION_ORDER"
          defaultValue={form.PRODUCTION_ORDER.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>

        <Label marginTop={formMargin} tag="textsmall_strong">
          Site
        </Label>

        <Select
          onChange={(event) => handleInputChange(event)}
          size="small"
          defaultValue={form.SITE_ID.value}
          id="SITE_ID"
          disabled={sites.length <= 1}
        >
          {sites.map((el: TSelect) => {
            return <SelectOption key={el.id} label={el.name} value={el.id} />;
          })}
        </Select>

        <Label marginTop={formMargin} tag="textsmall_strong">
          Product
        </Label>
        <Select
          onChange={(event) => handleInputChange(event)}
          size="small"
          defaultValue={form.PRODUCT_ID.value}
          id="PRODUCT_ID"
        >
          {products.map((el: TSelect) => {
            return <SelectOption key={el.id} label={el.name} value={el.id} />;
          })}
        </Select>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 20,
            marginTop: formMargin,
          }}
        >
          <div>
            <Label tag="textsmall_strong">Start Date</Label>
            <DatePicker
              size="small"
              id="PO_START_DATE_TIMESTAMP"
              defaultValue={form.PO_START_DATE_TIMESTAMP.value}
              onChange={(value) =>
                handleDateChange('PO_START_DATE_TIMESTAMP', value, 'start')
              }
            />
          </div>
          <div>
            <Label tag="textsmall_strong">End Date</Label>
            <DatePicker
              size="small"
              id="PO_END_DATE_TIMESTAMP"
              defaultValue={form.PO_END_DATE_TIMESTAMP.value}
              onChange={(value) =>
                handleDateChange('PO_END_DATE_TIMESTAMP', value, 'end')
              }
            />
          </div>
        </div>
        <Label marginTop={formMargin} tag="textsmall_strong">
          Workplan
        </Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            onChange={(event) => handleInputChange(event)}
            size="small"
            defaultValue={form.WORK_PLAN_ID.value}
            id="WORK_PLAN_ID"
          >
            {workplans.map((el: TSelect) => {
              return <SelectOption key={el.id} label={el.name} value={el.id} />;
            })}
          </Select>
          <Button
            buttonType="button"
            type="primaryOutline"
            width="100px"
            onClick={() => handleEditWorkplan(form.WORK_PLAN_ID.value)}
          >
            Edit
          </Button>
        </div>
        {editingWorkplan && (
          <div>
            <Label tag="textsmall_strong">Edit Workplan Steps</Label>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <Typography tag="textsmall_strong">Operation</Typography>
              <Typography tag="textsmall_strong">Step</Typography>
            </div>
            {workplanSteps.map((stepId, index) => {
              const step = selectedScenario?.data.operations.byId[stepId];
              if (!step) return null;

              return (
                <div
                  key={index}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <Typography tag="textsmall">
                    {step.OPERATION.value}
                  </Typography>
                  <Select
                    id={`step-${index}`}
                    size="small"
                    defaultValue={step.STEP.value}
                    onChange={(event) =>
                      handleStepChange(index, event.target.value)
                    }
                  >
                    {generateStepOptions(10)}{' '}
                    {/* Assuming a maximum of 10 steps */}
                  </Select>
                </div>
              );
            })}
            <Stack direction="row" distribute="space-around">
              <Button
                buttonType="button"
                type="primaryOutline"
                width="200px"
                onClick={() => setEditingWorkplan(false)}
              >
                Cancel
              </Button>
              <Button
                buttonType="button"
                type="primaryNoShadow"
                width="200px"
                onClick={handleSubmitWorkplan}
              >
                Update
              </Button>
            </Stack>
          </div>
        )}
        <Label marginTop={formMargin} tag="textsmall_strong">
          Current Step
        </Label>
        <Select
          onChange={(event) => handleInputChange(event)}
          size="small"
          defaultValue={Math.floor(form.PO_LAST_STEP_WIP.value).toString()}
          id="PO_LAST_STEP_WIP"
        >
          <SelectOption key={0} label="None" value="0" />
          {steps.map((el: TSelect) => {
            return <SelectOption key={el.id} label={el.name} value={el.id} />;
          })}
        </Select>

        {Math.floor(form.PO_LAST_STEP_WIP.value) > 0 && (
          <>
            <Label marginTop={formMargin} tag="textsmall_strong">
              {
                steps.find(
                  (obj) =>
                    parseInt(obj.id) ===
                    Math.floor(form.PO_LAST_STEP_WIP.value),
                )?.name
              }{' '}
              completed percentage
            </Label>
            <Slider
              defaultValue={Math.round((form.PO_LAST_STEP_WIP.value % 1) * 100)}
              min={0}
              max={99}
              valueSufix="%"
              sliderType="minimized"
              width={'420px'}
              onChange={(event) => handlePercentageChange(event)}
            />
          </>
        )}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormProductionOrders;
