import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import { update } from '@/store/storeSlice';
import { TreeItem } from '@/store/types';
import {
  Button,
  Checkbox,
  Input,
  Label,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormShiftsTimePicker from './FormShiftsTimePicker';

export type TFormProductionOrders = {
  SHIFT_HANDOVER_END_TIME: TreeItem<number>;
  SHIFT_HANDOVER_START_TIME: TreeItem<number>;
  SHIFT: TreeItem;
  END_TIME: TreeItem<number>;
  START_TIME: TreeItem<number>;
  MO: TreeItem<string>;
  TU: TreeItem<string>;
  WE: TreeItem<string>;
  TH: TreeItem<string>;
  FR: TreeItem<string>;
  SA: TreeItem<string>;
  SU: TreeItem<string>;
};

/**
 * FormShifts Component
 * Manages the form for editing shift details within a simulation scenario
 * Handles form submission, input changes, time changes, and closing the form drawer
 *
 * @returns {JSX.Element} - The rendered component
 */
const FormShifts = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  const InitialFormProductionOrders: TFormProductionOrders = {
    SHIFT: { value: '', originalValue: '' },
    END_TIME: { value: 0, originalValue: 0 },
    START_TIME: { value: 0, originalValue: 0 },
    SHIFT_HANDOVER_START_TIME: { value: 0, originalValue: 0 },
    SHIFT_HANDOVER_END_TIME: { value: 0, originalValue: 0 },
    MO: { value: 'false', originalValue: 'false' },
    TU: { value: 'false', originalValue: 'false' },
    WE: { value: 'false', originalValue: 'false' },
    TH: { value: 'false', originalValue: 'false' },
    FR: { value: 'false', originalValue: 'false' },
    SA: { value: 'false', originalValue: 'false' },
    SU: { value: 'false', originalValue: 'false' },
  };

  const [form, setForm] = useState(InitialFormProductionOrders);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { id, value, type, checked } = event.target as HTMLInputElement & {
      id: string;
      value: string;
      type: string;
      checked: boolean;
    };
    console.log(event.target);
    const updateForm = (id: keyof TFormProductionOrders, newValue: unknown) => {
      let v = newValue;

      if (typeof form[id] === 'number') {
        v = parseInt(v as string);
      }
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: v,
        },
      }));
    };

    updateForm(
      id as keyof TFormProductionOrders,
      type === 'checkbox' ? (checked ? 'true' : 'false') : (value as string),
    );
  };

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setForm(InitialFormProductionOrders);
  };

  /**
   * handleTimeChange Function
   * Updates the form state when a time value changes
   * Sets the new time value for the specified form field
   *
   * @param {keyof TFormProductionOrders} id - The form field ID
   * @param {number} value - The new time value
   */

  const handleTimeChange = (id: keyof TFormProductionOrders, value: number) => {
    console.log(value);
    setForm((prevForm) => ({
      ...prevForm,
      [id]: {
        ...prevForm[id],
        value: value,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      update({
        id: simulationParams.formItem,
        area: 'shifts',
        content: form,
      }),
    );

    handleCloseDrawer();
  };

  const selectedItem =
    simulationParams.formItem && selectedScenario
      ? selectedScenario.data.shifts.byId[simulationParams.formItem]
      : null;

  useEffect(() => {
    if (!selectedItem) return;

    const formToChange: TFormProductionOrders = {
      SHIFT: {
        value: selectedItem.SHIFT.value,
        originalValue: selectedItem.SHIFT.originalValue,
      },

      START_TIME: {
        value: parseInt(selectedItem.START_TIME.value),
        originalValue: parseInt(selectedItem.START_TIME.originalValue),
      },
      END_TIME: {
        value: parseInt(selectedItem.END_TIME.value),
        originalValue: parseInt(selectedItem.END_TIME.originalValue),
      },
      SHIFT_HANDOVER_END_TIME: {
        value: parseInt(selectedItem.SHIFT_HANDOVER_END_TIME.value),
        originalValue: parseInt(selectedItem.SHIFT_HANDOVER_END_TIME.originalValue),
      },
      SHIFT_HANDOVER_START_TIME: {
        value: parseInt(selectedItem.SHIFT_HANDOVER_START_TIME.value),
        originalValue: parseInt(selectedItem.SHIFT_HANDOVER_START_TIME.originalValue),
      },
      MO: {
        value: selectedItem.MO.value.toString(),
        originalValue: selectedItem.MO.value.toString(),
      },
      TU: {
        value: selectedItem.TU.value.toString(),
        originalValue: selectedItem.TU.value.toString(),
      },
      WE: {
        value: selectedItem.WE.value.toString(),
        originalValue: selectedItem.WE.value.toString(),
      },
      TH: {
        value: selectedItem.TH.value.toString(),
        originalValue: selectedItem.TH.value.toString(),
      },
      FR: {
        value: selectedItem.FR.value.toString(),
        originalValue: selectedItem.FR.value.toString(),
      },
      SA: {
        value: selectedItem.SA.value.toString(),
        originalValue: selectedItem.SA.value.toString(),
      },
      SU: {
        value: selectedItem.SU.value.toString(),
        originalValue: selectedItem.SU.value.toString(),
      },
    };
    setForm(formToChange);
  }, [selectedItem]);

  if (!selectedItem || !selectedScenario) return;

  const formMargin = 30;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <h5 style={{ paddingBottom: 20, color: '#8a00e5' }}> Edit Shift</h5>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 20,
            marginBottom: formMargin,
            marginTop: formMargin / 2,
          }}
        >
          <div>
            <Label tag="textsmall_strong">Site</Label>
            <Typography tag="textsmall">
              {
                selectedScenario.data.sites.byId[selectedItem.SITE_ID.value]
                  .SITE.value
              }
            </Typography>
          </div>
          <div>
            <Label tag="textsmall_strong">Shift Calendar</Label>
            <Typography tag="textsmall">
              {
                selectedScenario.data.shift_calendar.byId[
                  selectedItem.SHIFT_CALENDAR_ID.value
                ].RESOURCE_SHIFT_CALENDAR.value
              }
            </Typography>
          </div>
        </div>

        <Label tag="textsmall_strong">Shift</Label>
        <Input
          size="small"
          id="SHIFT"
          defaultValue={form.SHIFT.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>

        <Label tag="textsmall_strong" marginTop={formMargin}>
          Working Days
        </Label>

        <Typography tag="textsmall">
          <div>
            <Checkbox
              id="MO"
              isChecked={form.MO.value === 'true'}
              label="Monday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Checkbox
              id="TU"
              isChecked={form.TU.value === 'true'}
              label="Tuesday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Checkbox
              id="WE"
              isChecked={form.WE.value === 'true'}
              label="Wednesday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Checkbox
              id="TH"
              isChecked={form.TH.value === 'true'}
              label="Thursday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Checkbox
              id="FR"
              isChecked={form.FR.value === 'true'}
              label="Friday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Checkbox
              id="SA"
              isChecked={form.SA.value === 'true'}
              label="Saturday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div>
            <Checkbox
              id="SU"
              isChecked={form.SU.value === 'true'}
              label="Sunday"
              value="true"
              onChange={(event) => handleInputChange(event)}
            />
          </div>
        </Typography>

        <Stack direction="row" gap={40}>
          <div>
            <Label tag="textsmall_strong" marginTop={formMargin}>
              Working Start Time
            </Label>
            <FormShiftsTimePicker
              id="START_TIME"
              seconds={form.START_TIME.value}
              onChange={(value) => handleTimeChange('START_TIME', value)}
            />
          </div>
          <div>
            <Label tag="textsmall_strong" marginTop={formMargin}>
              Working End Time
            </Label>
            <FormShiftsTimePicker
              id="END_TIME"
              seconds={form.END_TIME.value}
              onChange={(value) => handleTimeChange('END_TIME', value)}
            />
          </div>
        </Stack>
        {!isNaN(form.SHIFT_HANDOVER_END_TIME.value) && (<Stack direction="row" gap={40}>
          <div>
            <Label tag="textsmall_strong" marginTop={formMargin}>
              Handover Start Time
            </Label>
            <FormShiftsTimePicker
              id="SHIFT_HANDOVER_START_TIME"
              seconds={form.SHIFT_HANDOVER_START_TIME.value}
              onChange={(value) => handleTimeChange('SHIFT_HANDOVER_START_TIME', value)}
            />
          </div>
          <div>
            <Label tag="textsmall_strong" marginTop={formMargin}>
              Handover End Time
            </Label>
            <FormShiftsTimePicker
              id="SHIFT_HANDOVER_END_TIME"
              seconds={form.SHIFT_HANDOVER_END_TIME.value}
              onChange={(value) => handleTimeChange('SHIFT_HANDOVER_END_TIME', value)}
            />
          </div>
        </Stack>)}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormShifts;
