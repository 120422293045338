/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from '@/store';
import {
  ShiftCalendarTree,
  ShiftTree,
  SiteTree,
  TSimulationMenuOptions,
  TreeItem,
  WipItemParams,
  WipTree,
  WipTreeElement,
  Pause,
  PauseShift,
} from '@/store/types';
import { generateUID } from '@/utils/helpers';
import {
  Input,
  Stack,
  Table,
  useDebounceCallback,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { ReactNode, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Shifts.module.scss';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { changeExpanded } from '@/store/storeSlice';
import { ContextMenu } from '../ContextMenu';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';

/**
 * Converts seconds into a string formatted as HH:MM
 *
 * @param {number} seconds - The number of seconds to convert
 * @returns {string} - The formatted time string
 */
const convertSeconds = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return (
    (hours.toString().length < 2 ? '0' + hours.toString() : hours.toString()) +
    ':' +
    (minutes.toString().length < 2
      ? '0' + minutes.toString()
      : minutes.toString())
  );
};

/**
 * Generates a calendar view for working days
 * Returns a JSX element displaying the calendar
 *
 * @param {any[]} arr - The array of working days
 * @returns {JSX.Element} - The rendered calendar element
 */
const generateCalendar = (arr: any[]) => {
  if (arr[0] === undefined) return '';

  return (
    <Stack horizontalAlign="center" key={generateUID()}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 20px)',
          fontSize: 9,
        }}
        key={generateUID()}
      >
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          M
        </div>
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          T
        </div>
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          W
        </div>
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          T
        </div>
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          F
        </div>
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          S
        </div>
        <div key={generateUID()} style={{ textAlign: 'center' }}>
          S
        </div>

        {arr.map((item: any) => {
          return (
            <div
              key={generateUID()}
              style={{ textAlign: 'center', height: 10, width: 20 }}
            >
              {item.value === 'true' || item.value === true ? (
                <div
                  key={generateUID()}
                  className={styles.CalendarDaySelected}
                ></div>
              ) : (
                <div
                  key={generateUID()}
                  className={styles.CalendarDayNotSelected}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </Stack>
  );
};

interface ProductTreeRender extends WipItemParams {
  SHIFT: TreeItem;
  START_TIME: TreeItem;
  END_TIME: TreeItem;
  WORKING_DAYS: TreeItem;
  PAUSES: TreeItem;
  HANDOVER: TreeItem;
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};

type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: keyof WipTree;
};

/**
 * Shifts Component
 * Renders a table of shifts with various functionalities such as filtering, expanding rows, and context menu actions
 * Manages the state for selected row, anchor element, and filter query
 */
const Shifts = () => {
  const { setSimulationParams } = useContext(SimulationCtx);
  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilterQuery(event.target.value);
      console.log(event.target.value);
    },
    1000,
  );

  const [filterQuery, setFilterQuery] = useState<string>('');

  /**
   * Generates an object with children from sites, shift calendars, shifts, pause shifts, and pauses tree objects
   * Returns an array of product structure objects
   * SITE > SHIFT CALENDAR > SHIFT
   * @param {WipTreeElement<SiteTree>} sitesObject - The sites tree object
   * @param {WipTreeElement<ShiftCalendarTree>} shiftsCalendarObject - The shift calendars tree object
   * @param {WipTreeElement<ShiftTree>} shiftsObject - The shifts tree object
   * @param {WipTreeElement<PauseShift>} pauseShiftObject - The pause shifts tree object
   * @param {WipTreeElement<Pause>} pausesObject - The pauses tree object
   */
  const generateObjectWithChildren = (
    sitesObject: WipTreeElement<SiteTree>,
    shiftsCalendarObject: WipTreeElement<ShiftCalendarTree>,
    shiftsObject: WipTreeElement<ShiftTree>,
    pauseShiftObject: WipTreeElement<PauseShift>,
    pausesObject: WipTreeElement<Pause>,
  ): TProductStructure[] => {
    const objectWithChildren: any[] = [];
    const itemMap: { [itemId: string]: TProductStructure } = {};

    sitesObject.allIds.forEach((itemId: string) => {
      const item = sitesObject.byId[itemId];

      const newItem: TProductStructure = {
        id: item.SITE_ID.originalValue,
        area: 'sites',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.SITE_ID.originalValue,
          title: item.SITE.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          SHIFT: item.SITE.value,
          START_TIME: '',
          END_TIME: '',
          WORKING_DAYS: '',
          PAUSES: '',
          HANDOVER: '',
          children: [],
        },
        children: [],
      };

      objectWithChildren.push(newItem);
      itemMap[itemId] = newItem;
    });

    shiftsCalendarObject.allIds.forEach((itemId: string) => {
      const item = shiftsCalendarObject.byId[itemId];
      const siteId = item.SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        id: item.RESOURCE_SHIFT_CALENDAR_ID.value,
        area: 'shift_calendar',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.RESOURCE_SHIFT_CALENDAR_ID.value,
          title: item.RESOURCE_SHIFT_CALENDAR.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          SHIFT: item.RESOURCE_SHIFT_CALENDAR.value,
          START_TIME: '',
          END_TIME: '',
          WORKING_DAYS: '',
          PAUSES: '',
          HANDOVER: '',
          children: [],
        },

        children: [],
      };

      if (
        objectWithChildren.find((obj) => obj.id === siteId) &&
        objectWithChildren.find((obj) => obj.id === siteId).children
      ) {
        objectWithChildren
          .find((obj) => obj.id === siteId)
          .children.push(newItem);
      } else {
        console.log('error' + siteId);
      }

      // itemMap[siteId].children.push(newItem);

      // Store the item in the map for later reference
      itemMap[itemId] = newItem;
    });

    shiftsObject.allIds.forEach((itemId: string) => {
      const item = shiftsObject.byId[itemId];
      const shiftCalendarID = item.SHIFT_CALENDAR_ID.value;
      const siteId = item.SITE_ID.value;

      if (!sitesObject.byId[siteId]) return;

      const pauses = pauseShiftObject.allIds
        .filter(
          (pauseId: string) =>
            pauseShiftObject.byId[pauseId].SHIFT_ID.value ===
            item.SHIFT_ID.value,
        )
        .map((pauseId: string) => {
          const pause =
            pausesObject.byId[pauseShiftObject.byId[pauseId].PAUSE_ID.value];
          return pause
            ? `${convertSeconds(parseInt(pause.START_TIME.value))}-${convertSeconds(parseInt(pause.END_TIME.value))}`
            : '';
        })
        .filter((pause) => pause !== '')
        .join('; ');

      const newItem: TProductStructure = {
        id: item.SHIFT_ID.value,
        area: 'shifts',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        data: {
          key: item.SHIFT_ID.value,
          title: item.SHIFT.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          SHIFT: item.SHIFT.value,
          START_TIME: convertSeconds(parseInt(item.START_TIME.value)),
          END_TIME: convertSeconds(parseInt(item.END_TIME.value)),
          WORKING_DAYS: generateCalendar([
            item.MO,
            item.TU,
            item.WE,
            item.TH,
            item.FR,
            item.SA,
            item.SU,
          ]),
          PAUSES: pauses,
          HANDOVER: !isNaN(Number(item.SHIFT_HANDOVER_START_TIME.value))?convertSeconds(parseInt(item.SHIFT_HANDOVER_START_TIME.value)).concat(';', convertSeconds(parseInt(item.SHIFT_HANDOVER_END_TIME.value))):'',
          children: [],
        },

        children: [],
      };

      const isDuplicate = itemMap[shiftCalendarID].children.some(
        (child) => child.id === newItem.id,
      );

      if (!isDuplicate && !newItem.data.is_deleted) {
        itemMap[shiftCalendarID].children.push(newItem);
      }

      itemMap[shiftCalendarID].children.sort((a, b) => {
        const stepA = (a.data.START_TIME as string) || ''; // Use empty string as default value
        const stepB = (b.data.START_TIME as string) || ''; // Use empty string as default value

        return stepA.localeCompare(stepB);
      });
    });

    return objectWithChildren;
  };

  if (!selectedScenario) return <>No Scenario</>;

  const objectWithChildren = generateObjectWithChildren(
    selectedScenario.data.sites,
    selectedScenario.data.shift_calendar,
    selectedScenario.data.shifts,
    selectedScenario.data.pause_shift,
    selectedScenario.data.pauses,
  );

  const TableHeaders: TableHeaderType[] = [
    {
      title: 'Shift',
      align: 'left',
      columnKey: 'SHIFT',
      columnWidth: '200px',
    },
    {
      title: 'Start Time',
      align: 'center',
      columnKey: 'START_TIME',
    },
    {
      title: 'End Time',
      align: 'center',
      columnKey: 'END_TIME',
    },
    { title: 'Working Days', align: 'center', columnKey: 'WORKING_DAYS' },
    { title: 'Pauses', align: 'center', columnKey: 'PAUSES' },
    { title: 'Handover', align: 'center', columnKey: 'HANDOVER' },
  ];

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'shifts') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;

    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;

    if (area !== 'shifts') return;
    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input
          id="Filter"
          onChange={handleInputChangeDebounced}
          placeholder="Filter"
        />
      </div>

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />

      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        typographyTag="textsmall"
        pointerCursor={true}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowClick={(value) => handleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />
    </>
  );
};

export default Shifts;
