import { useState, useEffect } from 'react';
import axios from 'axios';
import { useUserDetails } from '@/hooks/index';
import ApiCall from '@/services/ApiConnector/ApiConnector';

interface AuthenticateRequest {
  agentId: number;
  apiKey: string;
  apiDescription: string;
}

interface AuthenticateResponse {
  token: string;
}

const usePostUsage = () => {
  const [token, setToken] = useState<string | null>(null);
  const { gid, username } = useUserDetails();

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'PROD') {
      return;
    }

    const fetchToken = async () => {
      const payload: AuthenticateRequest = {
        agentId: 5,
        apiKey: 'key66ba1f81-b9c1-4b39-98ca-9bdd38e81f71',
        apiDescription: 'Api key for Factory Digital Twin Simulator',
      };

      try {
        const response = await axios.post<AuthenticateResponse>(
          'https://store-api.data.siemens-energy.cloud/api/token',
          payload
        );
        setToken(response.data.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT !== 'PROD' || !token || !gid || !username) {
      return;
    }

    const postUsage = async () => {
      const payload = {
        gid,
        eMail: username,
        creation_Date: new Date().toISOString(),
        isMobile: /Mobi|Android/i.test(navigator.userAgent),
      };

      try {
        await ApiCall(
          'POST',
          '/app/usage',
          {
            baseUrl: 'https://store-api.data.siemens-energy.cloud',
            accessToken: `Bearer ${token}`,
          },
          {
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
          payload
        );
      } catch (error) {
        console.error('Error posting usage:', error);
      }
    };

    postUsage();
  }, [token, gid, username]);
};

export default usePostUsage;